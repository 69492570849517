.page {
  background: white;
  display: grid;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: 40% 60%;
  grid-template-rows: 60px 1fr auto;
  grid-template-areas: 
    "header header"
    "main-question multimedia"
    "footer_q footer_q";
}

.page_duplicate {
  background: white;
  display: grid;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 60px 1fr auto;
  grid-template-areas: 
    "header"
    "main-question"
    "footer_q";
}
.page-no-media {
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr auto;
  grid-template-areas: 
    "header"
    "main-question"
    "footer_q";
}

.header {
  grid-area: header;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%
}

.main-question {
  grid-area: main-question;
}

.footer_q {
  grid-area: footer_q;
}

.multimedia {
  grid-area: multimedia;
  display: flex;
  max-width: 90vw;
  max-height: 90vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.multimedia>iframe {
  width: 90%;
  height: 70%;
}

@media screen and (max-width: 1020px) {
  .page {
    grid-template-columns: 1fr;
    grid-template-rows: 60px auto 1fr auto;
    grid-template-areas:
      "header"
      "multimedia"
      "main-question"
      "footer_q";
  }

  .page-no-media {
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr auto;
    grid-template-areas:
      "header"
      "main-question"
      "footer_q";
  }

  .main-question {
    padding: 0 12px;
    justify-content: top;
  }

  .question-description {
    margin-left: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .question-button {
    margin-left: 12px;
    width: 90%;
  }

  .multimedia {
    width: auto;
    max-width: 90vw;
    max-height: 33vh;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .multimedia>iframe {
    margin-top: 50px;
    margin-left: 20px;
    width: 400px;
    height: 200px;
  }
}

.embedded-media {
  max-width: 95%;
  max-height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: rgba(51, 51, 51, 0.7);
}

a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

button {
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header {
  grid-area: header;
}

.principal {
  grid-area: principal;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow-y: auto;
  padding-top: 80px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1;
}

.footer {
  grid-area: footer;
}

.footer {
  background-color: white;
  display: flex;
  width: 100%;
  justify-content: center;
  color: rgb(60, 60, 60);
  overflow-y: visible;
  flex-direction: column;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.logo {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 14px 16px 10px;
  max-height: 80px;
  max-width: 192px;
}

.front_footer {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px;
  padding: 10px 16px 8px 16px;
}

.seconds {
  animation: fadeIn 500ms ease-in both;
  font-size: 14px;
  font-weight: 400;
  color: rgb(44, 44, 44);
  line-height: 24px;
}

.seconds-text {
  animation: fadeIn 240ms ease-in both;
  padding-left: 4px;
}

.button_start {
  animation: fadeIn 600ms ease-in both;
  margin: 16px 8px 10px 0;
  padding: 10px 18px;
  min-height: 48px;
  background-color: rgb(31, 17, 160);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  font-weight: 550;
  font-size: 23px;
}

.button_start:hover {
  background-color: rgba(31, 17, 160, 0.726);
}

.return {
  animation: fadeIn 240ms ease-in both;
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  font-size: 12px;
  line-height: 16px;
  color: rgb(44, 44, 44);
  font-family: sans-serif;
  margin-left: 10px;
}

.welcome_content {
  animation: fadeIn 320ms ease-in both;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 720px;
  margin-top: 30px;
  margin-bottom: 80px;
  font-size: 20px;
  line-height: 28px;
}

.welcome_content_duplicate {
  animation: fadeIn 320ms ease-in both;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin-top: 30px;
  margin-bottom: 80px;
  font-size: 20px;
  line-height: 28px;
}

.title {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #333333;
}

.language-button {
  text-decoration: underline;
  background-color: transparent;
  color: rgba(44, 44, 44, 0.7);
  cursor: pointer;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  margin: 10px 0;
  padding: 0px;
}

.description {
  margin-top: 20px;
  white-space: pre-line;
}

.result-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1F11A0;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.footer_q {
  background-color: white;
  display: flex;
  justify-content: right;
  flex-direction: row;
  margin-right: 20px;
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.main-question {
  animation: fadeIn 250ms ease-in both;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  padding: 0 50px;
  overflow-y: auto;
}

.question-title {
  font-weight: bold;
  font-size: 28px;
  text-align: left;
  margin-bottom: 12px;
}

.question-description {
  margin-top: 12px;
  width: inherit;
  font-weight: unset;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: left;
  padding-bottom: 12px;
}

.question-button {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(102, 102, 102, 0.1);
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
  color: rgba(102, 102, 102, 0.699);
  max-width: 100%;
  outline: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
  opacity: 1;
  border-radius: 5px;
  font-weight: unset;
  font-size: 20px;
  line-height: 28px;
  margin-top: 1px;
  margin-bottom: 1px;
  max-width: 420px;
  justify-content: flex-start;
  text-align: left;
}

.question-button:hover {
  background-color: rgba(102, 102, 102, 0.342);
}

.letter {
  height: 24px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--sampler-comp-radius-keyhint);
  border-color: rgba(102, 102, 102, 0.6);
  background-color: rgb(255, 255, 255);
  font-size: 12px;
  line-height: 16px;
  min-width: 22px;
  font-weight: 700;
  margin-right: 5px;
}

.button_lite {
  background-color: rgb(31, 151, 17);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  width: 36px;
  height: 32px;
}

.button_lite.left {
  border-radius: 5px 0px 0px 5px;
}

.button_lite.right {
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid rgba(23, 196, 4, 0.534);
}

.button_lite:hover {
  background-color: rgba(30, 151, 17, 0.726);
}

.question {
  line-height: 24px;
}

.question-id {
  font-size: 16px;
  font-weight: 400;
}

.arrow {
  margin: 0 8px 0 4px;
}

.result {
  background: white;
  display: flex;
  height: 100vh;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.intermitente {
  border: 1px solid rgba(102, 102, 102, 0.699);
  animation: resplandorAnimation 2s;
  color: rgba(102, 102, 102);
}

.intermitente > .letter {
  background-color: rgba(102, 102, 102);
  color: white;
}

@keyframes resplandorAnimation {
  0% {
    border: 2px solid rgba(102, 102, 102, 0.699);
  }
  25% {
    border: 2px solid rgba(102, 102, 102, 0.288);
  }
  50% {
    border: 2px solid rgba(102, 102, 102, 0.699);
  }
  75% {
    border: 2px solid rgba(102, 102, 102, 0.288);
  }
  100% {
    border: 1px solid rgba(102, 102, 102, 0.699);
  }
}

.welcome{
  background: white;
  display: grid;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: auto;
  grid-template-rows: 60px auto 110px;
  grid-template-areas:
    "header"
    "principal"
    "footer"
}

button.button_reset {
  background-color: #1F11A0;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}


